/* MeR 240925 0512
*/
table {
    border: 1px solid silver;
    /*border-radius: 6px;*/

    &:hover{
        /*border-color: blueviolet;*/
        border-color:  rgb(101, 180, 195);
    }
}

.context-menu-container {
    z-index: 99999;
    display: inline;
    background-color: rgb(96, 18, 175);
}
  
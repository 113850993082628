/* MeR 221009
*/

.parts {
  position: relative;
  vertical-align: middle;
  width: 100%;
  right: 0;
  margin: 0;
  padding: 6px;
  background-color: goldenrod;
  justify-content: right;
  font-weight: bold;
}

.parts-select {
  width: calc(100% - 30px);
  justify-content: right;
  text-align: right;
  direction: rtl;
}


.pages {
    margin: 0;
    padding: 0;
    display: grid;
    height: 100vh;
}

.page-center {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

}

.page-fit {
    max-width: 100%;
    max-height: 90vh;
    margin: 6px;
    padding: 0;
}

/*
the parent :   .page-center  display: flex;
the child :
*/
.page-fit-center {
  position: absolute;
  top: 0; bottom: 0; 
  left: 0; right: 0;
  margin: auto ;
  padding: 5px;

}


  .thumbnails-grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-height: calc(100% - 5px) ;
    overflow-y: scroll;
    direction: rtl;
    grid-template-columns: repeat(4, 1fr);
  }

  .thumbnails-pane {
    transition: transform 0.3s ease-in-out;
    position: relative;
    padding: 6px;
    height: fit-content;
    resize: both;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    width: 25%;
    /*width: clamp(200px, 25%, 50%);*/
  }

  @media (max-width:400px) {
    .thumbnails-pane { width:50%; }
  }


  .thumbnails-pane:hover {
    transform: scale(1.05);
  }

  .thumbnails-image {
    max-width: 100%;
    max-height: 95vh;
    margin: 0;
    padding: 0;
    box-shadow: 1px 1px 3px #15240a;
}

  .thumbnails-info {
    position:absolute;
    width: calc(100% - 2*6px);
    bottom: 6px;
    height: 50px;
    background-color: rgba(153, 187, 150, 0.3);
    text-align:right;
    color: rgba(102, 7, 7, 0.5);
    font-size: 2rem;
    display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  }  
  
  .thumbnails-select {
    background-color: rgba(255,214,0,0.3);
    font-weight: bold;
    color: darkgreen;
  }  


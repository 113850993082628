.Services {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: auto auto auto auto;
    /*max-height:calc(100vh - 132px);*/
    justify-content: center;
    overflow: auto;
    /*background-color: rgb(208, 235, 166);*/
    margin-top: 2rem;
    padding: 0;
    height: auto;
    direction: rtl;
  }

  @media (orientation: portrait) { 
    .Services {
      grid-template-columns: auto;
      overflow-x: hidden;
      grid-gap: 0.6rem;
    }
  }  

  .Service {
    position: relative;
    display: block;
    width: 11rem;
    height: 15rem;
    border: 12px solid gray;
    justify-content: right;
    border-radius: 11px;
    padding: 1rem;
    margin: 1rem;
    text-decoration: none;
    color: rgb(16, 74, 16);
    background: linear-gradient(to bottom right, rgba(250,250,250,150), gray);
    /*background-color: silver;    */
    transition: 0.3s;
    font-size: 1rem;
    outline: none;
    &:hover {
      /*background-color: rgb(218, 205, 132);*/
      border-color: goldenrod;
      color: darkgreen;
      background: linear-gradient(to bottom right, rgb(235,240,182), gold);
    }
/*    
user-select: none;
pointer-events: none;
cursor: not-allowed;
*/
 }

  .Service img {
    position: relative;
    width: 100%;
  }

  .Service a {
    text-decoration: none;
    color: darkblue;
  }
  
  .ServiceName {
    width: 100%;
    text-align: center;

  }

  .ServiceName h1 {
    color: #333;
    font-weight: bold;

    &:hover {
      color: darkgreen;
    }
  } 

  .ServiceInfo {
    width: 100%;
  }

  .ServicePlan {
    width: 100%;
    color: blue;
  }  

  .Button {
    position: relative;
    border: 6px solid gray;
    border-radius: 6px;
    padding: 0.5rem;
    margin: 0.5rem 5%;
    text-decoration: none ;
    text-align: center;
    color: white;
    background-color: darkgreen;
    transition: 0.3s;
    font-size: 1.2rem;
    cursor: pointer;
    outline: none;
    width: 90%;
    height: auto;
    
    &:hover {
        color: white;
        font-weight: bold;
        background-color: green;
        border : 9px solid silver ;
    }
  }
  
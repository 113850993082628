/* MeR 221215
*/
img {
  border: 1px solid silver;
  border-radius: 6px;
}


#memorizeHeader {
  position: relative;
  top:0;
  left:5%;
  height: 2rem;
  width: 95%;
  border: 0px solid silver;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.MButton {
  position:absolute;
  opacity: 0.5;
  width: 6%;
  margin: 6px;
}

.NavButton {
  position:absolute;
  opacity: 0.3;
  top:44%;
  height: 12%;
  width: 6%;
  margin: 1px;
}

.NavButton:hover {
  opacity: 0.6;
}

/*
the parent :   .page-center  display: flex;
the child :
*/
.page-center {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  max-width: 100%;
}

.page-fit-center {
  position: relative;
  margin-top: 0.3rem;
  max-height: calc(100vh - 7.7rem);
}

.Part {
  position: absolute;
  border-radius: 80%;
  opacity: 0.4;
  height: 5%;
  width: 10%;
  color: white;
  border: 0px solid;

  &:hover{
    opacity: 0.5;
    font-size: large;
    font-weight: bold;
    border: 1px solid gold;
  }

}

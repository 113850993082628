
.timer-grid {
  position: relative;
  margin: 0;
  /*padding: 6px;*/
  width: 100%;
  display: grid;
  max-height: 100% ;
  overflow-y: scroll;
  direction: rtl
}


.timer-cell {
  width: 100%;
  transition: transform 0.3s ease-in-out;
  box-shadow: 1px 1px 3px #205105;
  cursor: pointer;
  display: inline-block;
  background-color: #606060;
}

.timer-cell:hover {
  transform: scale(1.01);
  font-weight: bolder;
}


.sign {
	margin: 0 auto;
  max-width: 1024px;
}

div.BasicInfos{
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
  margin-bottom: 1rem;
  font-size: large;
  color: darkblue;
}

input:focus {
  outline:none;

}
* {
    box-sizing: border-box;
  }
  
  /* Create two unequal columns that floats next to each other */
  .column {
    float: left;
    padding: 1rem;
  }
  
  .left {
    width: 20%;
  }
  
  .right {
    width: 75%;
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }


.Option {
  display: block;
  border: 5px solid #007bff;
  border-radius: 15px;
  padding: 15px 30px;
  text-decoration: none;
  color: #616A94;
  background-color: lightgray;
  transition: 0.3s;
  font-size: 1em;
  outline: none;
  user-select: none;
  margin: 0.5em;

  &:hover {
      color: white;
      background-color: #616A94;
  }
}
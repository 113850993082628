/* MeRrais Started 20141227
Standardized 231023 10:10 
*/

.postit-body {
    position:absolute;
	left: 0;
	top: 0;
    width: 100%;
    height: 100% ;
    font-size: 1rem;
    overflow-y: auto;
	resize: none;
	outline: none;
	border: none;
	background-color: transparent;
}

.postit-head{
	margin: 0;
	width: 100%;
	height: 1.5rem;
	vertical-align: middle;
}

.postit-item {
	position : fixed;
	border : 0px solid gray;
	z-index: 11111;
}


.postit-icon {
    width: 1rem;
	aspect-ratio : 1 / 1;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 11111;
}

.postit-icon:hover{
	border: 1px solid red;
}

.postit-content {
	left: 0px;
}

.postit-content:hover{
	border: 1px solid blue;
}


/*
MeR 230924 2041 : 2-level contextual menu for a div
*/
.context-menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100px;
	height: auto;
	background-color:rgba(150,150,150,0.5);
	border: 1px solid silver;
	list-style: none;
	padding: 0;
	margin: 0;
	display: none;
	z-index: 99999; /* front top */
  }
  
  .menu-item {
	cursor: pointer;
	height: 2rem;
  }
  
  .menu-item:hover {
	background-color: lightblue;
  }
  
  .menu-item .menu-text {
	font-size: 12px;
  }
  
  .sub-menu {
	position: absolute;
	top: 0;
	left: 100px;
	width: 60px;
	border: 1px solid gray;
	list-style: none;
	padding: 0;
	margin: 0;
	display: none;
	background-color: white;
	height: auto;
	/*max-height: 12rem;
	z-index: 11119;*/
  }

  .sub-menu-font {
	font-size: 0.8rem;
	width: 150px;
	background-color: lightgray;
	text-align: left;
  }
  
  .sub-menu .menu-item:hover {
	border: 1px solid white;
  }
  

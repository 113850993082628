body, html {
  background-color: #f6f7e5;
  color: #022901;
  overflow-x: hidden;
}


body {
  /*background-image: url('https://moshaf.akademy.ma/safahaat/000.jpg');*/
  background-attachment:fixed;
	margin: 0;
	padding: 0;
    width:100%;
    height: 100%;
	background-size:cover ;

}

header {
  position: absolute;
  top: 0;
z-index: 99;
width: 100%;
font-size: 0.9rem;
text-align:center;
height: 1rem;
max-height: 1rem;
background-color: rgba(41, 112, 10, 0.7);
overflow: hidden;
}

header:hover {
height: auto;
max-height: 60vh;
overflow-y: auto;
background-color: rgba(41, 112, 10, 0.3);;
}

code {
  font-family: source-code-pro, Menlo, Monaco;
}

th, td {
  /*text-align: end !important;*/
  vertical-align: middle !important;
}

th {
  background-color:aliceblue;
}

th:hover {
  background-color: rgba(0,158,213,255);
}

.degrade-green {
  background: linear-gradient(to bottom right, rgba(250,250,250,150), green);
}

.degrade-gold {
  background: linear-gradient(to bottom right, rgba(250,250,250,150), gold);
}

.degrade-red {
  background: linear-gradient(to bottom right, rgba(250,250,250,150), red);
}

.degrade-blue {
  background: linear-gradient(to bottom right, rgba(250,250,250,150), blue);
}

.degrade-gray {
  background: linear-gradient(to bottom right, rgba(250,250,250,150), gray);
}

.heading {
  width:100%;
  /* media queries */
  @media (max-width: 767px) {
    width:0;
    text-align: left;
  }
  @media (max-width: 400px) {
    width:0;
    text-align: center;
  }
}


.ClassLabel {
  color:darkred;
  text-align: right;
   }


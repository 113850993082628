

.Team {
    width: 100%;
    border-collapse: collapse;
  }
  
.Team tr:nth-child(even) {
  background: rgb(221, 205, 205);
}

.TeamMembers {
  position: relative;
  width: 100%;
  max-height: 90vh;
  border: 6px;
  border-color: blue;
}

.TeamMembers li:hover {
  transform: scale(1.02);
  font-weight: bolder;
}

.TeamMembersList {
  position: relative;
  direction: rtl;
  margin: 1%;
  padding: 3px;  /* 4: top right bottom left  &  2: top-bottom right-left*/
  width: 97%;
  display: grid;
  max-height: 100% ;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 6px;
  border-color: darkred;
}


.plan-grid {
    position: relative;
    margin: 0;
    width: 100%;
    display: grid;
    min-height: 200px ;
    overflow-y: auto;  /* scroll */
    overflow-x: hidden;
    direction: rtl;
    border: 1px;
    grid-gap:10px;
    height: calc(40vh - 10px);
    /*
    grid-template-columns: 1fr 1.5fr 1fr;
    grid-template-rows: auto auto 1fr 1fr 1fr auto auto;
    */
}

.plan-cell {
    width: calc(100% - 2rem);
    transition: transform 0.3s ease-in-out;
    margin: 3px;
    box-shadow: 1px 1px 3px #165eb1;
    cursor: pointer;
    display: inline-block;
    height: auto;
    border-color: gold;

}

.plan-cell:hover {
    transform: scale(1.01);
    font-weight: bolder;
}

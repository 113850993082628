/*
MeR 240705 0214 : From Results.css meTable From ToTable tajwyd.akademy.ma and Database.php GetResponse
MeR 230913 moved from Settings/style.css
*/

.element {
  position: relative;
  height: 100%;
  padding: 0.2rem;
  border: 0px solid silver;
  box-shadow: 0 0 4px 4px rgba(80, 80, 160, 0.2) inset;

}

.element:hover {
	border-color: #00f;
    box-shadow: 9px 6px 6px rgba(40, 99, 209, 0.7);
}

.results {
	position: relative;
	width: 100%;
	height: calc(100% - 2rem - 1.5rem);
	margin: auto auto;
}

.results-head{
	margin: 0;
    padding-top: 0.2rem;
	width: 100%;
	height: 2.4rem;
	background-color: rgb(10, 70, 100);
	color: azure;
	vertical-align: middle;
}

.results-name{
	font-size: 1.2rem;
	font-weight: bold;
	color: azure;
	width: 100%;
}


.results-foot{
    position: absolute;
    margin: 0;
    bottom: 0.2rem;
    font-size:0.8rem;
    width: calc(100% - 0.4rem);
	height: 1.5rem;
	background-color: rgba(127, 127, 127, 0.3);
	/*display: inline;*/
}


.results-foot p {
    padding: 0.2rem 0 0 0;
    margin: 0;
}

.results-id {
    font-size: 0.8rem;
    color: #333;
    text-align: right;
    direction: rtl;
}

.results-date {
    font-size: 0.8rem;
    color: #ecb1b1;
    text-align: left;
}

.results table {
    margin: 0;
    width: 100%;
    display: flex;
    flex-flow: column;
    max-height: calc(100% - 6px);
    font-size: 0.9rem;
    color: white;
    height: auto;
     /*table-layout: fixed;*/
}

.results table thead {
    flex: 0 0 auto;
    width: calc(100%);
}

.results table tbody {
    width: 100%;
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
    font-size: 1rem;
    font-weight: bold;
}

.results table th {
    background-color: rgb(10, 80, 120);
    font-size: 0.8rem;
}

.results table tr {
    width: 100%;
}

.results table thead, .results table tbody tr {
    display: table;
    table-layout: fixed;
}

.results td, .results th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
}

.results td:hover {
    text-overflow:initial;
}

.results td:nth-child(odd) {
    background: rgba(188, 182, 195, 0.25);
}

.results td:nth-child(even) {
    background: rgba(194, 200, 200, 0.25);
}

.results-rows {
    color: rgb(33, 89, 89);
    text-align: right;
}

#EtatStat {
    color: #2c1414;
}

#EtatApp {
    color: rgb(27, 27, 15);
}

.results td.selected {
    font-weight: bold;
	color: rgb(26, 7, 80);
	border-left: 1px solid rgb(23, 0, 128);
}

.icons {
  width: 1rem;
	aspect-ratio : 1 / 1;
	margin-top: 0.1rem;
	float: right;
}

.icons:hover{
	border: 1px solid lightblue;
}

.editable {
    color: #041330;
}

/*
CSS : table render with a great style 
*/

.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 1rem;
    min-width: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    color: #333;
}

.styled-table thead tr {
    background-color: #104078;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 4px 6px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.checked {
    font-weight: bold;
    padding: 6px;
    border: 3px solid #1c73c4;
}
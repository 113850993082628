

.Booking {
    width: 100%;
    border-collapse: collapse;
  }
  
  .Booking tr:nth-child(even) {
    background: #eee;
  }

  .Booking ul li a {
    display:block;
    list-style-type: circle;
   }

.Booking  ul.menu {
    display: table;
    margin-left: auto;
    margin-right: 0em;
}

.Booking ul {
  padding: 2rem;
  text-align: center;
}

.Booking li
/* horizontal menu layout */
 {
    display: inline-block;
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    margin-right:10px;
    padding: 20px;
    background-color: gray;

    &:hover{
      border: 3px solid #FFFFFF;
      border-radius: 10px;
      background-color: goldenrod;
  
    }
}

.Booking li a
/* link style */
 {
    color:#FFFFFF;
    font-size: 1em;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    padding: 0.7em 1em 0.7em 1em;

    &:hover
    /* link style on-mouse-over */
     {
        color:#FFFFFF;
        text-transform: uppercase;
        text-decoration: none;
    }
}

.Booking li.active a  {
    color:#FFFFFF;
    text-transform: uppercase;
    text-decoration: none;
}

.Booking li.active {
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    background-color: #dd417f;
}
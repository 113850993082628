

.Lesson {
    width: 100%;
    border-collapse: collapse;
  }
  
  .Lesson .Name,
  .Lesson .Description {
    width: 20%;
    text-align: center;
  }
  
  .Lesson tr:nth-child(even) {
    background: #eee;
  }

  
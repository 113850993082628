
.App {
  font-family: 'Tajawal', 'Montserrat', 'Cairo', 'Amiri', 'Verdana', 'Ubuntu', sans-serif; 
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.form-horizontal .control-label {
  text-align: right !important; /* !important added for priority in SO snippet. */
}

.Header {
  position: relative;
  z-index: 11111;
  top: 0;
  width: 100%;
  left:0;
  justify-content:center;
  vertical-align: bottom;
  max-height: 6rem;
  overflow: hidden;
  
}

.Header:hover {
  opacity: 1;
  max-height: 6rem;
}


.FullScreen {
  overflow: scroll;
  width: 99%;
  max-height: 90vh;
  margin: 0.5%;  
  direction: rtl;
  text-align: right;
  position: relative;
}

.Loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11111;
  background-color: rgba(0, 100, 0, 0.4);
  font-size: larger;
}

.Error {
  color: darkred;
  background: linear-gradient(to bottom right, yellow, orange);
  font-size: 2rem;
  text-align: center;
}

.navbar {
  position: relative;
  height: 3.6rem;
}

.navbar-custom {
  background-color: rgba(20,80,40,255);
  color:goldenrod;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1rem;
  display: flex;
  padding: 0.6rem;
  text-align: right;
}

.navbar-custom a {
justify-content: right;
}

nav a:hover {
  background-color: goldenrod;
  color: rgba(20,80,40,255);
  font-size: 1.2rem;
}

.navbar-right {
  position: relative;
  float: right;
}

.navbar-down {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11111;
  background-color:inherit; 
  color: inherit;
}


/*
@media (min-width: 700px) and (orientation: landscape) { ... }
@media (max-height: 700px), handheld or (orientation: portrait) { ... }
*/

.Navigation {
  position: relative;
}

.Logo {
  border-radius: 50%;
  height: 5rem;
  position: fixed;
  /*position: absolute;*/
  top:0;
  right:0;
}

.Icon {
  border-radius: 50%;
  height: 10rem;
}

.Footer {
  position: fixed;
   height: 1.5rem;
   width: 100%;
   bottom: 0;
   font-size: 0.8rem;
   font-style:normal;
   text-align: right;
   background-color:rgba(20, 80, 40, 255);
   color: #eee; 
   padding-right: 5%;
   opacity: 0.6;
 }

 .FooterEtat {
  position: relative;
  width: 50%;
  text-align: center;
  color: darkblue;
 }

 .center {
  display: flex;
  justify-content: center;
  align-items: center;
  /*color: white;*/
}

.top-right {
  position:absolute;
  top:0;
  right:0;
}

.Elements {
  position: relative;
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  row-gap: 1rem;
  padding: 0;
  overflow: scroll;
  direction: rtl;
  width: 100% ;
  height: max-content;
  margin-bottom: 2rem;
}

@media (orientation: portrait) { 

  .Elements {
    top:3rem;
    grid-template-columns: repeat(2, 1fr);
    height: max-content;
  }
  @media (max-width: 600px) {
    .Elements {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}


.Element {
  position: relative;
  width: 14rem;
  justify-content: right;
  padding: 1rem;
  margin: 1rem;  /*top right bottom left*/
  transition: 0.3s;
  font-size: 1rem;
  cursor: pointer;
  height: fit-content;

  background-color: rgb(14, 66, 3);
  color: var(--theme-text);

  border-radius: 0.125rem;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 0 0;
  display: flex;

  border: 1px solid gold; /* var(--theme-border-white-high-contrast);*/
  border-right: 0.2rem solid gold;
  border-bottom: 0.2rem solid gold;
  box-shadow: 1px 1px 12px rgb(54, 114, 14);

}

  .Element:hover {
      
      background-color: green;
      transform: scale(1.06);
      /*color: rgb(12, 13, 70); 
      background-color: #17c766;*/
  }

  .Element img {
    position: relative;
    width: 60%;
  }

  .Home {
    margin: 1rem;
    width: 100%;
    text-align: center;
  }

.modal-header .btn-close {
  height: 1rem;
  background-color: silver;
  border-color: darkred;
}
/*
.Member {
    width: 100%;
    border-collapse: collapse;
    
  }
  
  .Member tr:nth-child(even) {
    background: #eee;
  }
*/

/* 221202 Tab Container */
.Tabs {
  justify-content: center;
  height: auto;
  background: rgb(231, 242, 231);
  border-radius: 1rem;
  padding-bottom: 6px;
  @media (max-width: 768px) {
    padding: 1rem 0;
  }
}

/* Tab Navigation 
padding: top right bottom left;
*/
.TabsNav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 0.6rem 0 0.6rem;
}

.TabsNav li {
  width: 24%;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  border-radius: 1rem;
  padding: 0.6rem;
}

.TabsNav li:hover {
  color: white;
  background: green;
}

.TabsNav li.active {
  font-weight:bold;
  color: gold;
  border-color: gold;
  background: darkgreen;
}


/**/
.Members {
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  grid-template-columns: repeat;
  display: flex;
  flex-wrap: wrap;    
  padding: 0;
  grid-gap: 5px;
  overflow-y: scroll;
  background-color: rgb(211, 226, 187);
  /*margin: 1%;*/
  text-align: right;
  direction: rtl;
  /*width: max-content;*/
  width: 100%;
  height: 60vh; /*!*/
}

  .Member {
    width: 12rem;
    justify-content: right;
    border: 1px solid goldenrod; 
    padding: 0.6rem;
    margin: 0.6rem;
    transition: 0.3s;
    font-size: 1rem;
    cursor: pointer;
    height: fit-content;
  
    color: var(--theme-text);
    /*box-shadow: 0 1.6px 3.6px 0 gray,0 0.3px 0.9px 0 green;*/
    border-radius: 0.125rem;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 0 0;
    display: flex;
    position: relative;
    border-right: 0.2rem solid goldenrod;
    border-bottom: 0.2rem solid goldenrod;
    box-shadow: 1px 1px 12px rgb(54, 114, 14);
  }
  
    .Member:hover {
        transform: scale(1.05);
        background-color: darkgreen;
        /*background-color: #17c766;*/
    }
  
    .Member img {
      position: relative;
      width: 75%;
    }
  
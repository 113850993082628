.Notes {
  margin: 0;  
}

.Notes .Pane2 {
  max-height: 180px;
  /*overflow-y: scroll;*/
}

.comments-grid {
  position: relative;
  margin: 0;
  padding: 3px;  /* 4: top right bottom left  &  2: top-bottom right-left*/
    width: 100%;
    display: grid;
    max-height: 100% ;
    overflow-y: auto; /* hide scroll bar if not needed */
    overflow-x: hidden;
    direction: rtl;
    border: 1px;
    border-color: darkred;
    /*height: fit-content;*/
  }


  .comments-cell {
    width: calc(100% - 3px);
    transition: transform 0.3s ease-in-out;
    margin: 0;
    box-shadow: 1px 1px 3px #165eb1;
    cursor: pointer;
    display: inline-block;  /*make a div not larger than its contents !*/
    height: fit-content;
    /*width: clamp(200px, 25%, 50%);*/
  }

  /*
  @media (max-width:1600px) {
    .comments-cell { width:100%; }
  }
*/
  .comments-cell:hover {
    transform: scale(1.01);
    font-weight: bolder;
  }

/*
  .Pane1 {
    height: 60%;
  }

  .Pane2 {
    height: 40%;
  }
*/

  .b_rc_gb_sub_title {
    margin-bottom: 8px;
    line-height: 20px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.b_paractl {
  display: inline;
  text-overflow: ellipsis;
  line-height: 20px;
}
/*
MeR 230924 2041 : 2-level context-menu for a div 
MeR 231023 1504 : 3-level tree-menu : Used for the first time in math.akademy.ma
MeR 240925 1412 : Feedback from ocr.1ai.ma (Check) 
*/

#treeContainer {
	position:relative;
	max-height: 100%; 
	overflow-y:auto;

	/*display: flex;*/
}


#meTree {
    list-style-type: none;
	width: 100%;
	margin: 0;
    padding: 0;
	direction: rtl;
}  

#meTree ul {
	padding: 0.2rem 1rem 0.2rem 0.5rem;
	
  }


#meTree li {
    cursor: pointer;
	display: block;
	color: rgb(222,222,222);
	/*margin: 0.2rem 0.5rem;*/
	font-size: 1rem;
	font-weight: normal;
}

#meTree li.selected {
    font-weight: bold;
	color: white;
}


#meTree li.collapsed {
    display: none;
}

#meTree .label {
	position: relative;
	width: 100%;
	height: 2.4rem;
	display: block;
	padding: 0.4rem 1rem;
	text-align: right;
	&:hover {
		border: 1px solid silver;
		/*border: 1px solid silver;*/
	}
}


.Node1 {
	font-size: 1.2rem;
    background-color: rgb(50, 85, 85);
  }

  .Node2 {
	font-size: 1.1rem;
    background-color: rgb(50, 100, 100);
  }

  .Node3 {
	font-size: 1rem;
    background-color: rgb(50, 115, 115);
  }

  .Node4 {
	background-color: rgb(50, 130, 130);
  }
  
 .Sides {
	position: relative;
	display: flex;
	margin: 0;
	padding: 0;
	width: 100%;
	overflow-y: auto; 
	height: calc(100vh - 5rem);
	/*background-color: black #1a2a2a; rgba(51,73,138,255); #2F4F4F */
}

.side {
  position: relative;
  margin: 0;
  padding: 1px;
  overflow-y: auto;
  direction: rtl;
  &:hover {
	border: 1px solid gray;
	/*	z-index: 99999;	*/
	}
}

.vside {
	display: inline-block;
	top: 0;
	height: 100%;  
}

.hside {
	left: 0;
	width: calc(100% - 5px);
  
	  /* overflow-y: auto; */
  }
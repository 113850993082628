

.Course {
    width: 100%;
    border-collapse: collapse;
}
  
.Course tr:nth-child(even) {
  background: #eee;
}

.toRegister {
  margin: 1rem 0;
  padding: 1rem 0;
  color: black;
  height: 4rem;
  background-color:goldenrod;
  text-align:center;
  border: 3px solid silver;
  border-radius: 12px;
  font-weight: bold;
  font-size: 1.1rem;
}

.toRegister:hover {
  background-color:gold;
  border-color: yellow;
  font-size: 1.2rem;
  color: darkred;
}


.Classes {
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  grid-template-columns: repeat;
  display: grid;
  flex-wrap: wrap;    
  padding: 0;
  grid-gap: 5px;
  /*max-height:calc(100vh - 132px);*/
  overflow-y: scroll;
  /*background-color: rgb(208, 235, 166);*/
  /*margin: 1%;*/
  text-align: right;
  direction: rtl;
  width: max-content ;
  height: calc(100%); /*!*/
}

.Classe {
  width: 14rem;
  justify-content: right;
  border: 1px solid gold; /* var(--theme-border-white-high-contrast);*/
  margin: 0.5rem;
  transition: 0.3s;
  font-weight: normal;
  font-size: 1rem;
  cursor: pointer;
  height: fit-content;

  background-color: rgb(4, 59, 48);
  color: var(--theme-text);
  box-shadow: 0 1.6px 3.6px 0 gray,0 0.3px 0.9px 0 green;
  border-radius: 0.125rem;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 0.5rem 0.5rem;
  display: flex;
  position: relative;
  border-inline-start: 0.25rem solid gold;
}

  .Classe:hover {
      color: rgb(12, 13, 70);
      transform: scale(1.05);
      /*background-color: #17c766;*/
  }

  .Classe img {
    position: relative;
    width: 60%;
  }

  .ButtonShadow {
    padding: 15px 25px;
    font-size: 2em;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    background-color: #04AA6D;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
  }
  
  .ButtonShadow:hover {background-color: #3e8e41}
  
  .ButtonShadow:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

/*
MeR 221105 10:59
*/

.grid {
  grid-column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: repeat(auto-fit,minmax(290px,1fr));
  margin: 0;
  padding: 0;
  display: grid;
}

.grid>.grid-item {
  align-content: stretch;
  align-items: stretch;
  display: flex;
}

a, li {
outline: 0;
}

.card {
  max-width: 100%;
  border: 1px solid var(--theme-border-white-high-contrast);
  outline-color: var(--theme-text);
  background-color: var(--theme-card-background);
  color: var(--theme-text);
  box-shadow: 0 1.6px 3.6px 0 var(--theme-box-shadow-medium),0 0.3px 0.9px 0 var(--theme-box-shadow-light);
  border-radius: 0.125rem;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 0 0;
  display: flex;
  position: relative;
}
.border-color-accent {
  border-color: var(--theme-border-accent)!important;
}
.border-left-lg {
  border-inline-start: 0.25rem solid var(--theme-border)!important;
}

.card .card-template {
  flex-grow: 1;
  grid-template:
      "supertitle supertitle"
      "title title"
      "detail icon"/1fr 3rem;
  gap: 0.5rem 1rem;
  padding: 1rem;
  display: grid;
}

.card .card-footer {
  border-top: 1px solid var(--theme-border);
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-inline: 1rem;
  display: flex;
}
.padding-block-xxs {
  padding-block: 0.5rem!important;
}

